/* eslint-disable jsx-quotes */
import Head from 'next/head';
export default function MetaSeo(props) {
  let { url, type, title, description, image, domain, card } = props;
  if (!url) {
    url = 'https://hanagold.vn';
  }
  if (!type) {
    type = 'website';
  }
  if (!title) {
    title = 'HanaGold - Đầu tư và tích lũy vàng công nghệ NFC';
  }
  if (!description) {
    description =
      'HanaGold là nền tảng đầu tư và tích lũy vàng tiện lợi với công nghệ NFC, giúp bạn bảo vệ tài sản và đạt được mục tiêu tài chính. Tham gia ngay !';
  }
  if (!image) {
    image =
      'https://hanagold.vn/assets/img/peacock-gold/img_peacock-gold_header.jpg';
  }
  if (!domain) {
    domain = 'hanagold.vn';
  }
  if (!card) {
    card = 'summary_large_image';
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content={card} />
        <meta property="twitter:domain" content={domain} />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Head>
    </>
  );
}
