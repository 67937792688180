
import {
    LOAD_HOT_NEWS, LOAD_NEWS_HOME
} from '../../constants/ActionTypes';
import { fetchApi } from "../../utils/api";

export async function loadMoreNews(payload) {

    try {
        let response = await fetchApi(`/app/post/get-list-post`, "get", payload);
        if (response.code !== 200) {
            return {
                rows: [],
            };
        }
        return response.data;
    } catch (error) {
        return {
            rows: [],
        };
    }
}
export async function searchPosts(payload){
        try {
        let response = await fetchApi(`/app/post/get-news-post`, "get", payload);
        if (response.code !== 200){
            return {
                rows: [],
            };
        }
        return response.data;
        } catch (error) {   
            return { rows: [],
            };
        }
}
export function saveNewsHot(payload) {
    return {
        type: LOAD_HOT_NEWS,
        payload
    };
}


export async function loadNewsHome(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/post/get-news?category=${(JSON.stringify(['hot']))}`, "get", payload);
        if (response.code === 200) {
            dispatch(saveNewsHome(response.data));
        }
        return response;
    } catch (error) {
        return {
            rows: [],
        };
    }
}
export function saveNewsHome(payload) {
    return {
        type: LOAD_NEWS_HOME,
        payload
    };
}

export async function getNewsHot(payload, dispatch) {
    try {
        let response = await fetchApi(`/app/post/get-list-post`, "get", payload);
        if (response.code === 200) {
            dispatch(saveNewsHot(response.data));
        }
        return response;
    } catch (error) {
        return {
            "signal": 0,
            "message": "ERROR",
            "code": 400
        };
    }
}