/* eslint-disable eqeqeq */
// const formatCurrency = (n) => {
//     return  (n + '').replace(/./g, function(c, i, a) {
//         return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
//     });
// };

const formatNumberConvertComanToDots = (num, fixed) => {
  if (!num || num === '') return '0';
  num = num.toFixed(fixed);
  let idx = num.toString().indexOf('.');
  if (idx === -1) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // 1234567 -> 1,234,567
  }
  let str = num
    .toString()
    .substring(0, idx)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // 0.1234567 -> 0,1234567
  let sub_str = num.toString().substring(idx + 1, num.toString().length);
  let sum = 0;
  for (let item of sub_str) {
    sum = sum + parseInt(item);
  }
  if (fixed === 0) {
    return str;
  }
  if (sum === 0) {
    return str;
  }
  return str + ',' + sub_str;
};

const formatterCurrency = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',
  minimumFractionDigits: 0,
});
const formatterNumber = new Intl.NumberFormat('de-DE');
const formatGold = num => {
  if (!num || num === '') return '0';
  const str = num.toString();
  const idx = str.indexOf('.');
  if (idx === -1) {
    return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // 1234567 -> 1,234,567
  }
  const pre = str.slice(0, idx);
  let aft = str.slice(idx + 1, idx + 4);

  let fix_pre = pre.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  let data = fix_pre;
  data = data.replace(/,/g, '-');
  data = data.replace(/\./g, ',');
  data = data.replace(/-/g, '.');
  let sum = 0;
  for (let i = 0; i < aft.length; i++) {
    sum = sum + Number(aft[i]);
  }
  if (sum === 0) {
    aft = '';
  } else {
    aft = `,${aft}`;
  }
  data = data + aft;
  if (data.indexOf(',000') > -1) {
    data = data.replace(',000', '');
  }
  return data;
};

const parseVideo = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const regExpTiktok = url.split('video/');
  const match = url.match(regExp);
  return match && match[7].length == 11
    ? `https://www.youtube.com/embed/${match[7]}`
    : regExpTiktok.length > 1
    ? `https://www.tiktok.com/embed/${regExpTiktok[1]}`
    : '';
};

const formatCurrency = formatterCurrency.format;
const formatNumber = formatterNumber.format;
export {
  formatCurrency,
  formatGold,
  formatNumber,
  formatNumberConvertComanToDots,
  parseVideo,
};
